/* eslint-disable no-shadow */
import Auth from '@/api/auth';

const state = {
  token: localStorage.getItem('userToken') || null,
  userId: localStorage.getItem('userId') || null,
  user: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  user: (state) => state.user,
};

const mutations = {
  setToken(state, value) {
    state.token = value;
  },

  setId(state, value) {
    state.userId = value;
  },
};

const actions = {
  // eslint-disable-next-line consistent-return
  async authApi({ commit }, user) {
    try {
      const response = await Auth.login(user);
      if (response?.data?.user_token) {
        const token = response.data.user_token;
        localStorage.setItem('userToken', token);
        localStorage.setItem('tokenDate', new Date());
        commit('setToken', token);
      }
      return response;
    } catch (error) {
      console.log('error authApi', error);
    }
  },

  // eslint-disable-next-line no-unused-vars
  authTb({ commit }, data) {
    const tbUrl = new URL(data.teachbase_auth_url);
    tbUrl.searchParams.set('access_token', data.user_token);
    tbUrl.searchParams.set('uid', data.teachbase_uid);
    tbUrl.searchParams.set('email', data.email);
    tbUrl.searchParams.set('redirect_url', localStorage.getItem('lkUrl') ? localStorage.getItem('lkUrl') : process.env.VUE_APP_REDIRECT_URL);
    return tbUrl.href;
  },

  // eslint-disable-next-line no-unused-vars, consistent-return
  async recoveryPassword({ commit }, email) {
    try {
      const response = await Auth.recoveryPassword(email);
      return response;
    } catch (error) {
      console.log('error recoveryPass', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
