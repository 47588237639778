<template>
  <div id="app">
    <div class="page__block">
      <router-view />
      <The-Footer />
    </div>
    <div class="modal__group">
      <transition name="fade">
        <The-Modal v-if="isActiveLoader" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheFooter from '@/components/TheFooter.vue';
import TheModal from '@/components/TheModal.vue';

export default {
  components: {
    TheFooter,
    TheModal,
  },
  computed: {
    ...mapGetters('loader', {
      isActiveLoader: 'isActive',
    }),
  },
};
</script>

<style>
@import "~@/assets/css/compiled.css";
@import "~@/assets/css/modal.css";
#app {
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  transition: opacity 0.5s;
  opacity: 0;
}
</style>
