<template>
  <div id="modal-preloader" class="modal__item modal__item--loadings">
    <div class="modal__container">
      <div class="modal__inner">
        <div v-if="activeModal.type === 'recovery'" class="modal__body">
          <div class="modal__close" @click="closeModal"></div>
          <div class="modal__content">
            <div class="modal__text align--center">
              <p v-if="activeModal.status == 200">
                Логин и пароль был отправлен на адрес почты, указанный при
                регистрации
              </p>
              <p v-if="activeModal.status == 404">
                При отправке письма произошла ошибка
              </p>
              <p v-if="activeModal.status == 403">
                Пользователь не является кандидатом
              </p>
            </div>
          </div>
        </div>
        <div v-if="activeModal.type === 'loading'" class="modal__body">
          <div class="modal__content">
            <div class="modal__preloader">
              <div class="preloader__item"></div>
              <div class="modal__title">Выполняется авторизация</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Modal',
  computed: {
    ...mapGetters('loader', {
      activeModal: 'activeModal',
    }),
  },
};
</script>
