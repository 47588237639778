import HTTP from './default';

export default {
  login(data) {
    return HTTP({
      url: '/user/login',
      method: 'POST',
      headers: {},
      data,
    });
  },
  recoveryPassword(data) {
    return HTTP({
      url: '/user/recovery-password/request',
      method: 'POST',
      headers: {},
      data,
    });
  },
};
