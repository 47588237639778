import axios from 'axios';
import router from '@/router';

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Content-type': 'application/json',
  },
  validateStatus: () => true,
});

HTTP.interceptors.response.use(async (response) => {
  if (
    response?.data?.detail === 'Недопустимый токен.' || response?.data?.detail === 'Пользователь неактивен или удален.'
  ) {
    localStorage.removeItem('userToken');
    window.location.reload();
  } else if (response.status === 404 && !response.data?.detail) {
    await router.push('/page-not-found');
  } else if (response.status === 500 && !response.data?.detail) {
    await router.push('/technical-error');
  }

  return response;
});

export default HTTP;
