import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    openModal(modalType) {
      this.$store.commit('loader/show', modalType);
    },
    closeModal() {
      this.$store.commit('loader/close');
    },
  },
});

const startApp = () => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};
const isDev = process.env.VUE_APP_IS_DEV === 'true' && process.env.VUE_APP_IS_TEST === 'false';

const isLkLink = document.location.pathname.includes('/webinars') || document.location.pathname.includes('/course_session');
const lkRedirectLink = process.env.VUE_APP_LK_REDIRECT + document.location.pathname;

// LOGOUT BLOCK
if (document.location.pathname === '/logout') {
  localStorage.removeItem('tokenDate');
  localStorage.removeItem('userToken');
  localStorage.removeItem('oauthUrl');
  localStorage.removeItem('lkUrl');
  localStorage.removeItem('authCallbak');

  window.location.href = process.env.VUE_APP_ROOT_URL;
}

// USERTOKEN / DEFAULT BLOCK
if (localStorage.getItem('userToken') && !localStorage.getItem('authCallbak')) {
  const tokenTime = new Date(localStorage.getItem('tokenDate'));
  const timeDiff = new Date().getTime() - tokenTime.getTime();
  const diffHours = Math.ceil(timeDiff / (1000 * 3600));

  if (diffHours > 24) {
    // Время token'а истекло
    localStorage.removeItem('tokenDate');
    localStorage.removeItem('userToken');
    startApp();
  } else {
    // Токен активный, редирект на Teachbase
    localStorage.setItem('tokenDate', new Date());

    if (isDev) {
      // eslint-disable-next-line no-unused-expressions
      isLkLink ? console.log('redirect to webinar') : console.log('redirect teachbase');
    } else {
      let redirectLink = process.env.VUE_APP_ROOT_URL;
      // eslint-disable-next-line max-len, no-unused-expressions
      isLkLink ? redirectLink = lkRedirectLink : redirectLink = process.env.VUE_APP_REDIRECT_URL;
      window.location.href = redirectLink;
    }
  }
} else {
  startApp();
}
