/* eslint-disable complexity */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Authorization.vue'),
  },
  {
    path: '/technical-error',
    component: () => import('../views/E500.vue'),
  },
  {
    path: '/webinars/(.*)',
    beforeEnter: (to, from, next) => {
      if (to.fullPath.includes('webinars')) {
        localStorage.setItem('lkUrl', `${process.env.VUE_APP_LK_REDIRECT}${to.fullPath}`);
      }
      next({ name: 'Home' });
    },
  },
  {
    path: '/course_sessions/(.*)',
    beforeEnter: (to, from, next) => {
      if (to.fullPath.includes('course_session')) {
        localStorage.setItem('lkUrl', `${process.env.VUE_APP_LK_REDIRECT}${to.fullPath}`);
      }
      next({ name: 'Home' });
    },
  },
  {
    name: 'oauthError',
    path: '/oauth/error',
    component: () => import('../views/EoAuth.vue'),
  },
  {
    name: 'authCallback',
    path: '/authorization/callback',
    component: () => import('../views/AuthCallback.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const isQuery = to.query.login && to.query.token;
  const isOauth = to.query.response_type && to.query.client_id && to.query.redirect_uri;
  const queryLength = Object.keys(to.query).length;

  if (to.name === 'authCallback') {
    localStorage.removeItem('authCallbak');
  }

  if (queryLength > 0 && to.name !== 'authCallback') {
    if (!isQuery && !isOauth) {
      next({ name: 'oauthError' });
    }
    if (isQuery) {
      const data = {
        login: to.query.login,
        token: to.query.token,
        subdivision_code: 'AGENT',
      };
      store.commit('loader/show', { type: 'loading' });
      store.dispatch('user/authApi', data).then((res) => {
        if (res.status !== 200) {
          store.commit('loader/close');
          next({ name: 'oauthError' });
        }
        store.dispatch('user/authTb', res.data).then((url) => {
          window.location.href = url;
        });
      });
    }

    if (isOauth) {
      let redirectUrl = `${process.env.VUE_APP_OAUTH}/?response_type=${to.query.response_type}&client_id=${to.query.client_id}&redirect_uri=${to.query.redirect_uri}`;
      if (to.query.state) {
        redirectUrl += `&state=${to.query.state}`;
      }
      localStorage.setItem('oauthUrl', redirectUrl);
      localStorage.setItem('authCallbak', 1);
      if (
        process.env.VUE_APP_IS_DEV === 'true' && process.env.VUE_APP_IS_TEST === 'false'
      ) {
        window.location.href = 'http://localhost:8080/';
      } else {
        window.location.href = process.env.VUE_APP_ROOT_URL;
      }
    }
  }
  next();
});

export default router;
