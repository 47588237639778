const data = {
  isActive: false,
  activeModal: null,
};

const mutations = {
  show(state, payload) {
    state.activeModal = payload;
    state.isActive = true;
    // компенсируем скроллбар
    document.body.style.marginRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
    document.body.style.overflow = 'hidden';
  },
  close(state) {
    state.isActive = false;
    setTimeout(() => {
      document.body.style.cssText = 'overflow: auto; margin-right: 0;';
    }, 350);
  },
};

const getters = {
  isActive: (state) => state.isActive,
  activeModal: (state) => state.activeModal,
};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
};
